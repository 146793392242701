.dimmer-loading>.content {
  width: 75%;
}

.dimmer-loading {
  z-index: 100000;
}

.dimmer-loading .bar {
  background: var(--color-secondary) !important;
}
