/* Colors */
:root {
  --color-primary:    #00ADEF;
  --color-secondary:  #B3CF3B;
  --color-tertiary:   #D10075;
  --color-background: #F5F5F5;
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ui.modal > .ui.header {
  background-color: var(--color-primary) !important;
  color: white !important;
}

.ui.modal .ui.button, .titled-header .ui.button {
  background-color: var(--color-secondary) !important;
  color: white !important;
}

table.ui {
  border-top: .2em solid var(--color-primary) !important;
}

.container-grid {
  display: flex !important;
}

.container-grid>* {
  flex: 1;
}

.container-grid>*:not(:first-child) {
  margin-left: 1.75em;
}

.contract-infos {
  display: flex;
  align-items: center;
}

.logo {
  width: 5em !important;
  height: 5em !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: grey;
}

.name>span:not(:last-child) {
  margin-bottom: 0.5em;
}

.name>span:first-child {
  font-weight: bold;
}

.name>span>span:first-child {
  margin-right: 0.5em;
}
