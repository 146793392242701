.splash-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

.splash {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 15%;
  padding-left: 15%;
  flex-direction: column;
  transition: cubic-bezier(0.645, 0.045, 0.355, 1)
}

.splash>img {
  object-fit: contain;
  width: 100%;
}

.splash>span {
  font-size: 1.25em;
  padding-top: 1em;
  text-align: right;
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 5%;
}

.loading-bar .bar {
  background: var(--color-secondary) !important;
}
