.sidebar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebar-container.navigation {
  top: 50px;
  height: calc(100% - 50px) !important;
}

#content {
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--color-background);
  height: 100%;
  padding-left: 1.75em;
  padding-right: 1.75em;
}

#content:not(.dummy) {
  overflow-y: scroll;
}

#content .container {
  margin-top: 1.75em;
}

#content .container:last-child {
  margin-bottom: 1.75em;
}

.sidebar {
  background-color: #f9fafb;
}

.sidebar>#header  {
  padding: 0.75em;
  font-size: 1em;
  text-align: right;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  color: black;
}

.sidebar>#header>img {
  height: 4em;
  margin: auto;
  margin-top: 0.5em;
}

.sidebar>#header>.version {
  font-weight: bolder;
  cursor: help;
}

.sidebar>#header>.version:hover {
  text-decoration: underline;
}

.sidebar>#items {
  margin-top: 1em;
}

.sidebar>#items .separator {
  color: var(--color-primary);
  font-size: 1.25em;
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.sidebar-container > .pusher {
  height: 100%;
}

.sidebar .item {
  color: var(--color-primary) !important;
  font-weight: 500 !important;
}

.sidebar .item.active {
  color: white !important;
  background-color: var(--color-secondary) !important;
  border: 0 !important;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.sidebar .item:not(.active):hover {
  color: #36474F !important;
  background-color: var(--color-primary) !important;
}

#content {
  transition: left .5s ease;
}

.sidebar.visible ~.pusher #content {
  left: 260px;
}
